import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import asyncComponent from './components/asyncComponent'

const Fueling = asyncComponent(() => import('./features/Fueling'))
const Idle = asyncComponent(() => import('./features/Idle'))
const Background = asyncComponent(() => import('./features/Background'))
const Authorized = asyncComponent(() => import('./features/Authorized'))
const Starting = asyncComponent(() => import('./features/Starting'))
const Started = asyncComponent(() => import('./features/Started'))
const Ready = asyncComponent(() => import('./features/Ready'))
const Complete = asyncComponent(() => import('./features/Complete'))
const Stopped = asyncComponent(() => import('./features/Stopped'))
const Stopped2 = asyncComponent(() => import('./features/Stopped2'))
const Inoperative = asyncComponent(() => import('./features/Inoperative'))
const FuelingResult = asyncComponent(() => import('./features/FuelingResult'))
const H2Transaction = asyncComponent(() => import('./features/H2Transaction'))
const NotOpen = asyncComponent(() => import('./features/NotOpen'))
const Calling = asyncComponent(() => import('./features/Calling'))
const IdleEvening = asyncComponent(() => import('./features/IdleEvening'))
const Test = asyncComponent(() => import('./features/Test'))
const WelcomeGreenPlanet = asyncComponent(() => import('./components/WelcomeGreenPlanet'))
const Welcome = asyncComponent(() => import('./features/Welcome'))
const ReceiptReady = asyncComponent(() => import('./features/ReceiptReady'))
const HoseWarning = asyncComponent(() => import('./features/HoseWarning'))
const NozzleReturnError = asyncComponent(() => import('./features/NozzleReturnError'))
const ImproperNozzleConnection = asyncComponent(() => import('./features/ImproperNozzleConnection'))
const NoProgress = asyncComponent(() => import('./features/NoProgress'))
const FullRecharging = asyncComponent(() => import('./features/FullRecharging'))
const IdleRecharging = asyncComponent(() => import('./features/IdleRecharging'))
const IdleNeptuneRecharging = asyncComponent(() => import('./features/IdleNeptuneRecharging'))

export default () => (
  <Router>
    <Switch>
      <Route path='/fueling' component={Fueling} />
      <Route path='/idle-evening' component={IdleEvening} />
      <Route path='/idle' component={Idle} />
      <Route path='/welcomegreenplanet' component={WelcomeGreenPlanet} />
      <Route path='/welcome' component={Welcome} />
      <Route path='/background' component={Background} />
      <Route path='/authorized' component={Authorized} />
      <Route path='/gettingreadytostart' component={Starting} />
      <Route path='/started' component={Started} />
      <Route path='/readytostart' component={Ready} />
      <Route path='/calling' component={Calling} />
      <Route path='/stopping' component={Complete} />
      <Route path='/stopped' component={Stopped} />
      <Route path='/stopped2' component={Stopped2} />
      <Route path='/fuelingresult' component={FuelingResult} />
      <Route path='/inoperative' component={Inoperative} />
      <Route path='/h2transaction' component={H2Transaction} />
      <Route path='/hosewarnings' component={HoseWarning} />
      <Route path='/nozzlereturnerror' component={NozzleReturnError} />
      <Route path='/impropernozzleconnection' component={ImproperNozzleConnection} />
      <Route path='/notopen' component={NotOpen} />
      <Route path='/receiptready' component={ReceiptReady} />
      <Route path='/noprogress' component={NoProgress} />
      <Route path='/fullrecharging' component={FullRecharging} />
      <Route path='/idlerecharging' component={IdleRecharging} />
      <Route path='/idleneptunerecharging' component={IdleNeptuneRecharging} />
      <Route path='/test/:languageSelected/:feature' component={Test} />
    </Switch>
  </Router>
)
