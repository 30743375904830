import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
//
import App from './app/App'
import registerFileSystem from './registerFileSystem'
import * as serviceWorker from './serviceWorker';
import store from './store'
//
import './index.scss'

registerFileSystem()

const params = new URLSearchParams(document.location.search);
window.playerId = params.get('playerId');
window.containerId = params.get('iframeId') || params.get('containerId');
window.duration = params.get('duration') ? params.get('duration') : null;

document.addEventListener('DOMContentLoaded', () => {
  window.parent.postMessage({ event: 'loaded', frameId: window.containerId }, '*')
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
  , document.getElementById('root'),
)

serviceWorker.register({
  onUpdate: registration => {
    console.info(
        'Received a service worker update, unregister old service worker and restart browser',
    );
    registration.unregister().then(() => {
      window.location.reload();
    });
  },
  onSuccess: () => {
    console.log('New service worker loaded');
  },
});
