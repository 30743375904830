import React, { useEffect, useState } from 'react';
import { setTranslations, setLocale, I18n } from 'react-i18nify';
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import translations from '../i18n/translations';
import { initializePostMessages } from './componentDataSlice'
import Router from '../router'
import './style/index.scss'
import fs from "bro-fs";

const App = () => {
  const languageSelected = useSelector(state => state.componentData.languageSelected, shallowEqual);
  const { containerId, duration, location } = window;
  const { pathname } = location
  const componentId = pathname.substring(pathname.lastIndexOf('/') + 1)
  const dispatch = useDispatch()
  const [fileSystemInitialized, setFileSystemInitialized] = useState(false);

  useEffect(() => {
    fs.init({ type: window.TEMPORARY, bytes: 5 * 1024 * 1024 }).then(() => {
      setFileSystemInitialized(true);
    })
  }, [])
  useEffect(
    () => {
      dispatch(initializePostMessages(containerId, duration, componentId))
      setTranslations(translations);
    },
    [containerId, duration, componentId, dispatch]
  )

  useEffect(
    () => {
      if (languageSelected) {
        setLocale(languageSelected)
      }
    },
    [languageSelected]
  )

  if (!fileSystemInitialized) return null;
  return (
    <I18n render={() =>
      <Router />
    }/>
  )
}

export default App
