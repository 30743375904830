import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  progress: 0,
  state: 'FUELING',
  languageSelected: 'nl',
  rechargingData: null
}

export const componentDataSlice = createSlice({
  name: 'componentData',
  initialState,
  reducers: {
    requestComponentData: (state, action) => {
      return { ...state, loading: true, ...action.payload };
    },
    createComponentData: (state, action) => {
      return { ...state, loading: false, ...action.payload };
    }
  },
});

export const { requestComponentData, createComponentData } = componentDataSlice.actions;

export default componentDataSlice.reducer;

const testData = {
  RefuelingProgress: {
    progress: 10,
  },
}

const checkForTestData = (node, data) => {
  if (data || window.top !== window.self) {
    return data
  }
  return testData[node]
}

const sendMessage = (message) => {
  if (window.top === window.self) {
    window.postMessage(message, '*')
  } else {
    window.parent.postMessage(message, '*')
  }
}

function shouldComponentReceiveRechargeData(componentId) {
  return componentId === 'fullrecharging' || componentId === 'idlerecharging' || componentId === 'idleneptunerecharging'
}

export const stopPlaying = () => (dispatch, getState) => {
  const { componentData: { containerId } } = getState()
  dispatch(createComponentData({ running: false }))
  sendMessage({ event: 'end', frameId: containerId })
}

export const initializePostMessages = (containerId, duration, componentId) => (dispatch, getState) => {
  console.info('Initializing post messages: containerId=', containerId, 'componentId=', componentId, 'duration=', duration)
  const onPostMessage = (message) => {
    const { data } = message
    // message (structure):
    // { 'data' :
    //   { 'containerId' : 'CONTAINER_IDENTIFIER_FROM_URL',
    //     'event' : 'componentData' || 'RefuelingProgress' || 'start' || 'stop'
    //   }
    // }

    // ignore messages from react
    if (data.containerId === containerId || data.frameId === containerId) {
      console.debug('Rx message: ', data)
      switch (data.event) {
        case 'componentData': {
          const componentDataOld = getState().componentData
          const componentData = checkForTestData(data.componentId, data.componentData)
          dispatch(createComponentData({ componentId: data.componentId, ...componentData }))
          if (componentDataOld.loading) {
            sendMessage({ event: 'loaded', frameId: containerId })
          }
          break
        }
        case 'fueling': {
          dispatch(createComponentData({ ...data.eventData }))
          break
        }
        case 'fuelingResult': {
          const { eventData } = data
          if (eventData.code && eventData.code.indexOf('#') !== -1) {
            eventData.code = eventData.code.substr(0, eventData.code.indexOf('#'))
          }
          dispatch(createComponentData({ ...eventData }))
          break
        }
        case 'language': {
          dispatch(createComponentData({ ...data.eventData }))
          break
        }
        case 'start': {
          sendMessage({ event: 'playing', frameId: containerId })
          dispatch(createComponentData({ running: true }))
          break
        }
        case 'stop': {
          dispatch(stopPlaying())
          break
        }
        case 'recharging': {
          dispatch(createComponentData({ rechargingData: { ...data.eventData } }))
          break
        }
        default:
      }
    }
  }
  window.addEventListener('message', onPostMessage)

  window.parent.postMessage(
    {
      event: 'subscribe',
      eventMessage: 'H2_LANGUAGE',
      frameId: window.containerId,
    },
    '*',
  );

  // subscribe to fueling data
  if (componentId === 'h2transaction') {
    window.parent.postMessage(
      {
        event: 'subscribe',
        eventMessage: 'H2_FUELING',
        frameId: window.containerId,
      },
      '*',
    );
  }

  // subscribe to fueling result data
  if (componentId === 'fuelingresult') {
    window.parent.postMessage(
      {
        event: 'subscribe',
        eventMessage: 'H2_FUELING_RESULT',
        frameId: window.containerId
      },
      '*'
    );
  }

  if (shouldComponentReceiveRechargeData(componentId)) {
    window.parent.postMessage(
      {
        event: 'subscribe',
        eventMessage: 'H2_RECHARGING',
        frameId: window.containerId
      },
      '*'
    )
  }

  sendMessage({ event: 'componentData', frameId: containerId, componentId })
  dispatch(requestComponentData({ componentId, containerId, duration }))
}
